// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth"
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore"
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage"

import VideoConverter from "convert-video"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCSYer5fP75z7QzMAt--UMpZ4sVlr1O28I",
  authDomain: "budcoversweb.firebaseapp.com",
  projectId: "budcoversweb",
  storageBucket: "budcoversweb.appspot.com",
  messagingSenderId: "895478131690",
  appId: "1:895478131690:web:0ff349b6c8abd4bdd6e093",
}

// Initialize Firebase
initializeApp(firebaseConfig)
const db = getFirestore()
const auth = getAuth()
const storage = getStorage()
// export const analytics = getAnalytics(app);

/* Auth Functions */
export const signIn = (email, password, setError) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      return {
        user: true,
        email: userCredential.user.email,
        uid: userCredential.user.uid,
      }
    })
    .catch(error => {
      const errorMessage = error.message
      if (errorMessage === "Firebase: Error (auth/wrong-password).") {
        return setError("La contraseña no coincide.")
      }
      if (errorMessage === "Firebase: Error (auth/user-not-found).") {
        return setError("El mail ingresado no esta registrado.")
      }
      setError("Comunicarse con Itesa")
    })
}

export const authListener = setLogin => {
  onAuthStateChanged(auth, user => {
    if (user) {
      setLogin(true)
    } else {
      setLogin(false)
    }
  })
}

/* Firestore Functions */
export const getAllDocuments = col => {
  return getDocs(collection(db, col)).then(querySnapshot => {
    let data = []
    querySnapshot.forEach(doc => {
      let docData = doc.data()
      docData.id = doc.id
      data.push(docData)
    })
    console.log("data :", data)
    return data
  })
}
//Se queda escuchando la base de datos y si hay un cambio en la Collection te actualiza la data
export const onGetAllDocuments = (col, callback) => {
  return onSnapshot(collection(db, col), callback)
}

export const getDocument = async (col, id) => {
  try {
    const docSnap = await getDoc(doc(db, col, id))
    return docSnap.data()
  } catch (error) {
    console.error(error)
  }
}

export const createDocument = (col, data) => addDoc(collection(db, col), data)

export const updateDocument = (col, id, newFields) =>
  updateDoc(doc(db, col, id), newFields)

export const deleteDocument = (col, id) => deleteDoc(doc(db, col, id))

export const uploadFile = (
  { onError, onSuccess, setProgress, file },
  location
) => {
  const storageRef = ref(
    storage,
    `${location}/${new Date().getTime().toString()}- ${file.name}`
  )
  const uploadTask = uploadBytesResumable(storageRef, file)
  uploadTask.on(
    "state_changed",
    snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      setProgress(Math.round(progress))
      // console.log("Upload is " + progress + "% done");
      switch (snapshot.state) {
        case "paused":
          console.log("Upload is paused")
          break
        case "running":
          console.log("Upload is running")
          break
      }
    },
    error => {
      onError(error)
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
        onSuccess(downloadURL)
        console.log("File available at", downloadURL)
      })
    }
  )
}

export const setUserCover = async (
  { userName, email, province, instagram, artist, song, description, birthday },
  videoUrl
) => {
  console.log("adentrooo de la funcion set user cover")
  try {
    const userData = {
      userName,
      email,
      province,
      instagram,
      description,
      birthday,
    }
    const coverData = {
      user: userName,
      artist,
      song,
      instagram,
      videoImg: "",
      videoUrl,
      videoId: "",
      votes: [],
    }
    const { id: userId } = await createDocument("users", userData)
    coverData.userId = userId
    const res = await createDocument("covers", coverData)
    localStorage.setItem("user", email)
    return res
  } catch (error) {
    console.error("error :", error)
  }
}

export const registerAndVote = async (
  { userName, email, province, instagram, description, birthday },
  coverId
) => {
  try {
    const userData = {
      userName,
      email,
      province,
      instagram,
      description,
      birthday,
    }

    const user = await createDocument("users", userData)
    const { votes } = await getDocument("covers", coverId)
    votes.push(email)
    const res = await updateDocument("covers", coverId, { votes })
    localStorage.setItem("user", email)
    return res
  } catch (error) {
    console.error("error :", error)
  }
}
