import React, { useState, useEffect } from "react"
import ReactPlayer from "react-player"
import styles from "./participate.module.scss"
import { IsMobileDevice } from "../../../utils"
import { StaticImage } from "gatsby-plugin-image"
import { uploadFile } from "../../../utils/firebase"


// Components
import Button from "../Button"
import Layout from "../Layout"
import SvgIcon from "../SvgIcon"
import Loading from "../Loading"
import Form from "../FormComponent"

const Participate = () => {
  const [isMobile, setIsMobile] = useState()
  const [file, setFile] = useState("")
  const [video, setVideo] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [progress, setProgress] = useState(0)


  const isLoading = loading ? styles.isLoading : ""

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  const onChange = async e => {
    try {
      setLoading(true)
      const file = e.target.files[0]
      setFile(file)
      const onError = e => {
        console.error(e)
        setError(true)
      }
      const onSuccess = url => {
        setVideo(url)
        setLoading(false)
      }
      const res = await uploadFile(
        { onSuccess, onError, setProgress, file },
        "Covers"
      )
    } catch (error) {
      setLoading(false)
      setError(true)
      console.error("error :", error)
    }
  }

  const uploadVideo = () => {
    console.log("upload video")
    const uploadButton = document.getElementById("load-video")
    uploadButton.click()
  }

  const onSuccess = data => {
    const coverData = JSON.stringify(data)
    localStorage.setItem("coverData", coverData)
    window.location.replace(`/budstage/success/`)
  }

  return (
    <div className={styles.participate}>
      <Layout type="secondary" footerType="secondary">
        <SvgIcon name="textura-fondo" size="47vw" className={styles.fondo} />
        <div className={`${styles.participateBody} ${isLoading}`}>
          {!isMobile ? (
            <>
              <div className={styles.formContainer}>
                <h1>SUBÍ TU TEMA.</h1>
                <Form
                  type="upload"
                  urlVideo={video}
                  noButton={isMobile ? true : false}
                  className={styles.participateForm}
                  onSuccess={onSuccess}
                />
              </div>
              <div className={styles.uploadContainer}>
                <input
                  type="file"
                  name="video"
                  id="load-video"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={onChange}
                  hidden
                />
                <div className={styles.video}>
                  {video ? (
                    <ReactPlayer
                      url={video}
                      controls
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <StaticImage
                      src="../../../images/BudStage/Cover.jpg"
                      quality={70}
                      formats={["AUTO"]}
                      alt="Experiencia"
                      layout="fullWidth"
                      className={styles.imgStatic}
                    />
                  )}
                </div>
                <div className={styles.imageFooter}>
                  <p>{file.name || "*Formato .mp4 / .avi. min res 720 px"}</p>
                  <Button
                    label="SUBIR TEMA"
                    iconSide="left"
                    onClick={uploadVideo}
                    icon={
                      <SvgIcon
                        name="upload-arrow"
                        size="0.833vw"
                        className={styles.uploadArrow}
                      />
                    }
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={styles.formContainer}>
              <h1>SUBÍ TU TEMA.</h1>

              <div className={styles.uploadContainerMobile}>
                <input
                  type="file"
                  name="video"
                  id="load-video"
                  accept="video/*"
                  onChange={onChange}
                  hidden
                />
                <div className={styles.video}>
                  {video ? (
                    <ReactPlayer
                      url={video}
                      controls
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <StaticImage
                      src="../../../images/BudStage/Cover.jpg"
                      quality={70}
                      formats={["AUTO"]}
                      alt="Experiencia"
                      layout="fullWidth"
                      className={styles.imgStatic}
                    />
                  )}
                </div>
                <div className={styles.imageFooter}>
                  <p>{file.name || "*Formato .mp3 / .avi. min res 720 px"}</p>
                  <Button
                    label="SUBIR TEMA"
                    iconSide="left"
                    onClick={uploadVideo}
                    icon={
                      <SvgIcon
                        name="upload-arrow"
                        size="0.833vw"
                        className={styles.uploadArrow}
                      />
                    }
                  />
                </div>
              </div>

              <Form
                type="upload"
                urlVideo={video}
                noButton={isMobile ? true : false}
                className={styles.participateForm}
                onSuccess={onSuccess}
              />
            </div>
          )}
        </div>
      </Layout>
      <Loading progress={progress} loading={loading} />
    </div>
  )
}

export default Participate
