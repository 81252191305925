import React, { useState, useEffect } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { oneOf, string, func, arrayOf, shape } from "prop-types"
import { upload, vote } from "./schema"
import { setUserCover, registerAndVote } from "../../../utils/firebase"
import styles from "./form.module.scss"
import SvgIcon from "../SvgIcon"
import { IsMobileDevice, getUserData, setUserData } from "../../../utils"
import { provincias } from "./provincias.json"
import { uploadParticipant, voteUser, uploadVoter } from "../../../services"
import Cookies from "js-cookie"

const FormComponent = ({ type, urlVideo, coverId, onSuccess, className }) => {
  const [isMobile, setIsMobile] = useState()

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  const initialValues = {
    userName: "",
    email: "",
    province: "",
    instagram: "",
    artist: "",
    song: "",
    description: "",
    birthday: "",
    agree: false,
  }

  const inputs = [
    {
      name: "userName",
      label: "NOMBRE Y APELLIDO",
      type: "text",
      view: ["upload", "vote"],
    },
    {
      name: "birthday",
      label: "FECHA DE NACIMIENTO",
      type: "text",
      view: ["upload", "vote"],
    },
    {
      name: "province",
      label: "PROVINCIA",
      type: "select",
      options: provincias,
      view: ["upload", "vote"],
    },
    {
      name: "instagram",
      label: "INSTAGRAM",
      type: "text",
      view: ["upload", "vote"],
    },
    {
      name: "song",
      label: "NOMBRE CANCIÓN",
      type: "text",
      view: ["upload"],
    },
    {
      name: "email",
      label: "EMAIL",
      type: "email",
      view: ["upload", "vote"],
    },
    {
      name: "question",
      label: "ESCRIBE AQUÍ",
      type: "text",
      title: "¿QUÉ DIFERENCIA A BUDWEISER DE LAS DEMÁS CERVEZAS?",
      view: ["vote"],
    },
  ]

  const submit = async (values, { setSubmitting }) => {
    const budData = { ...values, videoUrl: urlVideo, td: Cookies.get("_td") }
    try {
      if (type === "upload") {
        setUserCover(values, urlVideo)
        window.dataLayer.push({
          event: "trackEvent",
          eventCategory: "Form",
          eventAction: "Click",
          eventLabel: "Video",
        })
        uploadParticipant(budData).then(response => {
          setSubmitting(false)
          if (response.ok) {
            window.dotq = window.dotq || []
            window.dotq.push({
              projectId: "10000",
              properties: {
                pixelId: "10100532",
                qstrings: {
                  et: "custom",
                  ec: "formulariocompleto",
                },
              },
            })

            onSuccess(values)
          }
        })
      } else {
        values.votedId = coverId
        const voteData = { ...values, td: Cookies.get("_td") }
        await uploadVoter(voteData).then(response => {
          setSubmitting(false)

          if (response.ok) {
            window.dotq = window.dotq || []
            window.dotq.push({
              projectId: "10000",
              properties: {
                pixelId: "10100532",
                qstrings: {
                  et: "custom",
                  ec: "votacionstagecompleta",
                },
              },
            })
            onSuccess()
          }
        })
        window.dataLayer.push({
          event: "trackEvent",
          eventCategory: "Form",
          eventAction: "Click",
          eventLabel: "Voto",
        })
        await voteUser(coverId)
        const { user, votes } = getUserData()
        if (user) {
          votes.push(coverId)
          setUserData({ user, votes })
        } else {
          const dataUser = {
            user: values.email,
            votes: [coverId],
          }
          setUserData(dataUser)
        }
      }
      /*       const create = type === 'upload' ? await setUserCover(values, urlVideo) : registerAndVote(values, coverId); */
    } catch (error) {
      console.error("error :", error)
      setSubmitting(false)
      if (error && type === "upload") {
        console.log("ERROR EN UPLOAD")
        // navigate("/BudStage-404")
        window.location.replace(`/budstage/404`)
      } else {
        console.log("ERROR EN VOTE")
        // navigate("/BudStage-404")
        window.location.replace(`/budstage/404`)
      }
    }
  }

  const formatDate = string => {
    const fragment = string.split("") || []
    const formated = fragment.reduce((acum, item, i) => {
      if ((i === 2 || i === 5) && item !== "/") acum.push("/")

      acum.push(item)
      return acum
    }, [])

    return formated.join("")
  }

  const renderInput = (input, values, setFieldValue) => {
    return input.view.includes(type) ? (
      <div
        key={input.name}
        className={`${styles.imputContainer} ${styles[[input.name]]}`}
      >
        {input.title && <p>{input.title}</p>}
        {/* <label>{input.label}</label> */}
        <Field
          type={input.type}
          id={input.name}
          name={input.name}
          value={values[input.name]}
          placeHolder={input.label}
          onChange={e => {
            const value = e.target.value || ""
            const toSet = input.name === "birthday" ? formatDate(value) : value
            setFieldValue(input.name, toSet)
          }}
        />
        <ErrorMessage
          name={input.name}
          component="div"
          style={{ fontSize: "15px", paddingLeft: "2%", color: "white" }}
        />
      </div>
    ) : null
  }

  const renderSelect = (input, values, handleChange, handleBlur) => {
    return input.view.includes(type) ? (
      <div
        key={input.name}
        className={`${styles.imputContainer} ${styles[[input.name]]}`}
      >
        <select
          name={input.name}
          value={values[input.name]}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {input.options.map(item => (
            <option value={item.value}> {item.label}</option>
          ))}
        </select>
        <ErrorMessage
          name={input.name}
          component="div"
          style={{ fontSize: "15px", paddingLeft: "2%", color: "white" }}
        />
      </div>
    ) : null
  }

  const schema = type === "upload" ? upload : vote

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      // validateOnChange={false}
      validationSchema={schema}
    >
      {({ isSubmitting, values, handleChange, handleBlur, setFieldValue }) => {
        return (
          <Form
            className={`${styles.form} ${styles[type]} ${className}`}
            style={{ paddingTop: "5%" }}
          >
            {inputs.map(input =>
              input.type === "select"
                ? renderSelect(input, values, handleChange, handleBlur)
                : renderInput(input, values, setFieldValue)
            )}

            <div className={styles.agree}>
              <Field
                type="checkbox"
                name="agree"
                style={{
                  height: "100%",
                  border: "1px solid white",
                  borderRadius: "0",
                  background: "none",
                }}
              />
              <label>
                He leído y acepto{" "}
                <a href="/budstage/tyc">Bases y Condiciones </a> ,{" "}
                <a href="/PoliticadePrivacidad.pdf" target="blank">
                  {" "}
                  Políticas de Privacidad{" "}
                </a>{" "}
                ,{" "}
                <a href="/Terminos-Condiciones.pdf" target="blank">
                  Términos y Condiciones{" "}
                </a>{" "}
                y activaciones de marketing.
              </label>
            </div>

            <button
              type="submit"
              disabled={
                isSubmitting ||
                !values.agree ||
                (type === "upload" && !urlVideo)
              }
            >
              {type === "upload" ? (
                "ENVIAR"
              ) : (
                <>
                  <SvgIcon name="heart-icon" size={isMobile ? "" : "1.458vw"} />{" "}
                  ENVIAR VOTO
                </>
              )}
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

FormComponent.defaultProps = {
  type: "upload",
  urlVideo: "",
  coverId: "",
  onSuccess: () => {},
}

FormComponent.propTypes = {
  type: oneOf(["upload", "vote"]),
  urlVideo: string,
  coverId: string,
  onSuccess: func,
  inputs: arrayOf(
    shape({
      name: string,
      label: string,
      type: string,
      view: arrayOf(string),
    })
  ),
}

export default FormComponent
